export var NAME = "onoffcanvas";
export var EVENT_KEY = "." + NAME;
export var EventName = {
    HIDE: "hide" + EVENT_KEY,
    SHOW: "show" + EVENT_KEY
};
export var ClassName = {
    SHOW: "is-open"
};
export var Selector = {
    DATA_TOGGLE: '[data-toggle="onoffcanvas"]'
};
export var OcOption = {
    createDrawer: true,
    hideByEsc: true
};
