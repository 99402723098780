export function getSelectorFromElement(element) {
    var selector = element.getAttribute("data-target");
    if (!selector || selector === "#") {
        selector = element.getAttribute("href") || "";
    }
    try {
        var $selector = document.querySelectorAll(selector);
        return $selector.length > 0 ? selector : null;
    }
    catch (error) {
        throw new Error("Target Not Found!");
    }
}
export function uniqueArr(arr) {
    var uniqueArray = arr.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
    });
    return uniqueArray;
}
export function selectorArray(arrs) {
    var divArr = [];
    for (var _i = 0, arrs_1 = arrs; _i < arrs_1.length; _i++) {
        var element = arrs_1[_i];
        var selector = getSelectorFromElement(element);
        divArr.push(selector);
    }
    return divArr;
}
